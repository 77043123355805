import React from "react"
import Header from "../components/header"
// import Footer from "../components/footer"
import Image from "../components/image"

export default () => (
  <div>
    <Header />
    <main>
      <div>
        <Image />
      </div>
    </main>
    {/* <Footer /> */}
  </div>
)
