import React from "react"
import Helmet from "react-helmet"
import { Navbar, NavItem } from "react-materialize"
import "../components/page.css"

const Header = () => (
  <header className="blue darken-4">
    <Helmet>
      <link
        href="http://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet"
      />
      <link
        href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css"
        rel="stylesheet"
      />
      <script src="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/js/materialize.min.js" />
    </Helmet>
    <nav>
      <div className="nav-wrapper blue darken-4">
        <div className="container">
          <Navbar className="noShadow blue darken-4"
            brand={
              <a href="/home" className="brand-logo">
                <i className="material-icons">verified_user</i>Zentech
              </a>
            }
            alignLinks="right"
          >
            <NavItem href="/">Home</NavItem>
            <NavItem href="/">Plans</NavItem>
            <NavItem href="/">Contact Us</NavItem>
          </Navbar>
        </div>
      </div>
    </nav>
  </header>
)

export default Header
