import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = () => (
  <StaticQuery
  query={graphql`
  query {
    desktop: file(relativePath: { eq: "ZentechOffice.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`}
    render={data => <Img fluid={data.desktop.childImageSharp.fluid} />}
  />
)
export default Image
